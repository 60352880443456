/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */


// Inject YouTube API script
var tag = document.createElement('script');
tag.src = "//www.youtube.com/player_api";
var firstScriptTag = document.getElementsByTagName('script')[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

var player;
function onYouTubePlayerAPIReady() {
  // create the global player from the specific iframe (#video)
  player = new YT.Player('splash-video');
}

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        //
        var offset;
        offset = 60;
        if(navigator.userAgent.match(/iPad/i) != null){
          offset = 100;
        }

        window.viewportUnitsBuggyfill.init();
        if (document.getElementById('patched-viewport')) {
          window.addEventListener('resize', viewportUnitsBuggyfill.refresh, true);
        }

        $('a[href*="#"]:not([href="#"],[data-slide="prev"],[data-slide="next"],[data-toggle="collapse"],[data-toggle="tab"],[href="#tab"])').click(function() {
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            target = (target.length ? target : $('[name=' + this.hash.slice(1) +']'));
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - offset
              }, 1000);
              $('.navbar-collapse.in').removeClass("in");
              return false;
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        /*
         *  add_marker
         *
         *  This function will add a marker to the selected Google Map
         *
         *  @type  function
         *  @date  8/11/2013
         *  @since 4.3.0
         *
         *  @param $marker (jQuery element)
         *  @param map (Google Map object)
         *  @return  n/a
         */

        function add_marker($marker, map) {
          // var
          var latlng = new google.maps.LatLng($marker.attr('data-lat'), $marker.attr('data-lng'));
          // create marker
          var marker = new google.maps.Marker({
            position: latlng,
            map: map,
            title: 'Pianola & co',
            url: 'https://www.google.co.uk/maps/dir//Pianola+%26+Co,+240+Crow+Rd,+Glasgow+Metropolitan+Area+G11+7PZ/@55.8759291,-4.321605,17z/'
          });
          marker.addListener('click', function(){
            window.open(this.url, '_blank');
          });

          marker.setVisible(Boolean($marker.attr('data-show-marker')));
          // add to array
          map.markers.push(marker);
          // if marker contains HTML, add it to an infoWindow
          if ($marker.html()) {
            // create info window
            var infowindow = new google.maps.InfoWindow({
              content: $marker.html()
            });
            // show info window when marker is clicked
            google.maps.event.addListener(marker, 'click', function() {
              infowindow.open(map, marker);
            });
          }
        }

        /*
         *  center_map
         *
         *  This function will center the map, showing all markers attached to this map
         *
         *  @type  function
         *  @date  8/11/2013
         *  @since 4.3.0
         *
         *  @param map (Google Map object)
         *  @return  n/a
         */

        function center_map(map, zoom) {
          // vars
          var bounds = new google.maps.LatLngBounds();
          // loop through all markers and create bounds
          $.each(map.markers, function(i, marker) {
            var latlng = new google.maps.LatLng(marker.position.lat(), marker.position.lng());
            bounds.extend(latlng);
          });

          // only 1 marker?
          if (map.markers.length === 1) {
            // set center of map
            map.setCenter(bounds.getCenter());
            map.setZoom(zoom);
          } else {
            // fit to bounds
            map.fitBounds(bounds);
          }
        }

        /*
         *  new_map
         *
         *  This function will render a Google Map onto the selected jQuery element
         *
         *  @type  function
         *  @date  8/11/2013
         *  @since 4.3.0
         *
         *  @param $el (jQuery element)
         *  @return  n/a
         */
        
        var mapStyle = [
            {
                "featureType": "landscape",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#bbff00"
                    },
                    {
                        "saturation": 43.400000000000006
                    },
                    {
                        "lightness": 37.599999999999994
                    },
                    {
                        "gamma": 1
                    }
                ]
            },
            {
                "featureType": "landscape",
                "elementType": "geometry",
                "stylers": [
                    {
                        "gamma": "1.33"
                    },
                    {
                        "hue": "#66ff00"
                    },
                    {
                        "lightness": "-5"
                    },
                    {
                        "saturation": "-50"
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#66ff00"
                    },
                    {
                        "saturation": "-82"
                    },
                    {
                        "lightness": 11.200000000000017
                    },
                    {
                        "gamma": 1
                    }
                ]
            },
            {
                "featureType": "poi",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "gamma": "0.59"
                    }
                ]
            },
            {
                "featureType": "road.highway",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#66ff00"
                    },
                    {
                        "saturation": -61.8
                    },
                    {
                        "lightness": 45.599999999999994
                    },
                    {
                        "gamma": 1
                    }
                ]
            },
            {
                "featureType": "road.arterial",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#ff0000"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 51.19999999999999
                    },
                    {
                        "gamma": 1
                    }
                ]
            },
            {
                "featureType": "road.local",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#66ff00"
                    },
                    {
                        "saturation": -100
                    },
                    {
                        "lightness": 52
                    },
                    {
                        "gamma": 1
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "hue": "#66ff00"
                    },
                    {
                        "saturation": "-54"
                    },
                    {
                        "gamma": "0.76"
                    },
                    {
                        "lightness": "5"
                    }
                ]
            },
            {
                "featureType": "water",
                "elementType": "all",
                "stylers": [
                    {
                        "hue": "#00beff"
                    },
                    {
                        "saturation": "-64"
                    },
                    {
                        "lightness": 2.4000000000000057
                    },
                    {
                        "gamma": "0.76"
                    }
                ]
            }
        ];

        function new_map($el) {
          // var
          var $markers = $el.find('.marker');
          // vars
          var $zoom = parseInt($el.attr('data-zoom'));
          var args = {
            zoom: $zoom,
            center: new google.maps.LatLng(0, 0),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            scrollwheel: false,
            styles: mapStyle
          };
          // create map
          var map = new google.maps.Map($el[0], args);
          // add a markers reference
          map.markers = [];
          // add markers
          $markers.each(function() {
            add_marker($(this), map);
          });
          // center map
          center_map(map, $zoom);
          // return
          return map;
        }

        /*
         *  document ready
         *
         *  This function will render each map when the document is ready (page has loaded)
         *
         *  @type  function
         *  @date  8/11/2013
         *  @since 5.0.0
         *
         *  @param n/a
         *  @return  n/a
         */
        // global var
        var map = null;
        $(document).ready(function() {
          $('.flex-map').each(function() {
            // create map
            map = new_map($(this));
          });
        });


      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
       },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        //
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.